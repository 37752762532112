#BlockTimetable {
    --padding-h: var(--padding-m);
    --padding-v: var(--padding-l);
    
    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xs);
        --padding-v: var(--padding-m);
    }
}

#BlockTimetable {
    padding: var(--padding-v) var(--padding-h);
    text-align: center;

    @include font-medium();
    
    h2 {
        @include font-script-xxlarge();
        color: var(--lightGreen);
        margin-bottom: var(--padding-xs);
    }
    
    h3 {
        @include font-script-xlarge();
        color: var(--lightGreen);
        margin-top: var(--padding-m);
        margin-bottom: var(--padding-xs);
    }

    a {
        color: var(--lightGreen);
    }

    ul {
        width: 85%;
        text-align: left;
        margin: auto;
        
        li {
            padding: rem(5) rem(5) rem(5) var(--padding-l);
            position: relative;

            &:nth-child(odd) {
                background-color: var(--lightGreen20);
            }

            &:nth-child(even) {
                background-color: var(--lightGreen40);
            }
        }

        strong {
            position: absolute;
            left: rem(5);
            top: rem(5);
        }
    }
    
    .date {
        @include font-large();
        margin-bottom: var(--padding-m);
    }
    
    .text{
        p:not(:last-child){
            margin-bottom: var(--padding-xs);
        }
    }
    
    .button {
        margin-top: var(--padding-s);
        display: inline-block;
        text-decoration: underline;
    }

    .image-wrapper {
        text-align: right;

        .text {
            @include font-script-xlarge();
        }
     
        .img {
            overflow: hidden;
            height: 100%;
            width: 100%;
            
            img {
                height: 160%;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    @media (max-width: $smartphone) {  
        .image-wrapper {
            width: 100%;
            height: auto;

            img {
                height: auto !important;
            }
        }

        h3 {
            margin-top: var(--padding-s);
        }

        .row > div:first-child {
            margin-bottom: var(--padding-m);
        }

        .image-row {
            margin-top: var(--padding-s);
        }

        .image-row:not(:last-child) {
            margin-bottom: var(--padding-s);
        }
    }

    @media (min-width: $smartphone) {
        .row {
            gap: var(--padding-s);
            display: flex;
            
            > div {
                padding-top: var(--header-height);
                padding-bottom: var(--padding-v);
                flex: 0 0 calc(50% - var(--padding-s) / 2);
            }

            > div:first-child {
                margin-top: 20vh;
            }
        }

        .row:not(:last-child),
        .image-row:not(:last-child) {
            margin-bottom: var(--padding-xl);
        }

        ul {
            width: 65%;
        }   

        .image-row {
            &.--1 {
                .image-wrapper {
                    width: 70vw;
                    height: 70vh;

                    img {
                        height: 160%;
                    }
                }
            }

            &.--2 {
                display: flex;
                justify-content: flex-end;

                .image-wrapper {
                    width: 40%;
                    height: 70vh;

                    img {
                        height: 160%;
                    }
                }
            }

            &.--3 {
                margin-top: 30vh;

                .image-wrapper {
                    width: 50%;
                    height: 40vw;
                }
            }

            &.--4 {
                display: flex;
                justify-content: flex-end;
                margin-top: -30vh;

                .image-wrapper {
                    width: 70vw;
                    height: 70vh;

                    img {
                        height: 180%;
                    }
                }
            }
        }
    }
}
