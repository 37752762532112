@use "sass:math";

:root {
  --y-header: 0;

  --padding-xl: #{math.div(120px, 16px) * 1rem};
  --padding-l: #{math.div(90px, 16px) * 1rem};
  --padding-m: #{math.div(60px, 16px) * 1rem};
  --padding-s: #{math.div(30px, 16px) * 1rem};
  --padding-xs: #{math.div(15px, 16px) * 1rem};
  --padding-xxs: #{math.div(10px, 16px) * 1rem};
  --padding-xxxs: #{math.div(5px, 16px) * 1rem};
  --padding-xxxxs: #{math.div(4px, 16px) * 1rem};

  --header-height: #{math.div(80px, 16px) * 1rem};
  
  --scrollbar-height: #{math.div(40px, 16px) * 1rem};
}

@media (max-width: $smartphone) {
    :root {
        --header-height: #{math.div(58px, 16px) * 1rem};
    }
}
