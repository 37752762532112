#BlockDresscode {
    --padding-h: var(--padding-m);
    --padding-v: var(--padding-xl);
    
    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xs);
        --padding-v: var(--padding-m);
    }
}

#BlockDresscode {
    padding: var(--padding-v) var(--padding-h);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: var(--lightGreen20);

    @include font-medium();
    
    h2 {
        @include font-script-xxlarge();
        color: var(--lightGreen);
        margin-bottom: var(--padding-s);
    }
    
    .text{   
        p:not(:last-child){
            margin-bottom: var(--padding-xs);
        }
    }

    @media (min-width: $smartphone) {
        br {
            display: none;
        }

        min-height: 70vh;

        h2 {
            margin-bottom: var(--padding-m);
        }

        .text{
            max-width: rem(840);

            p:not(:last-child){
                margin-bottom: var(--padding-s);
            }
        }
    }
}
