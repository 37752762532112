#Preloader {
    --bg: #000000;
}

#Preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    @include z-index($z-index-loader);
    background-color: var(--grey);
    
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;

    .icon {
        height: 100vh;
        width: 100vw;
    }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        opacity: 0;
    }

    svg {
        height: 100%;
        width: 100%;
        object-fit: cover;
        fill: var(--lightGreen);
        
        path {
            width: 100%;
            height: 100%;
        }
    }
}
