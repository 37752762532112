.block-slider-default {
    --width: 100%;
    --width-slide: 100%;
    --height: 70vh;
    --gap: var(--padding-xxs);
    --padding-h: var(--padding-l);
    --padding-v: 10vh;
    
    @media (max-width: $smartphone) {
        --width-slide: 80%;
        --padding-h: var(--padding-xs);
        --padding-v: var(--padding-s);
    }

    width: var(--width);
    max-width: rem(1400);
    margin: var(--margin) 0;
    padding: 0;
    user-select: none;
    margin: auto;
    overflow: hidden;
    
    > img {
        height: auto;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
        @include z-index(0)
    }
}

.block-slider-default__slider {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: var(--padding-v) var(--padding-h);

    cursor: grab;    
    &:active {
        cursor: grabbing;
    }

    .index {
        display: flex;
        justify-content: flex-end;
        @include font-script-xlarge();
    }

    > .holder {
        order: 1;
        position: relative;
        height: var(--height);
        max-height: var(--height);
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
        gap: var(--gap);
    }

    @media (max-width: $smartphone) {
        > .holder {
            -webkit-overflow-scrolling: touch;
            overflow: hidden;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .index {
             display: none;
        }

        .block-slider-default__item {
            min-width: 80vw;

            scroll-snap-align: start;
        }
    }
}

.block-slider-default__item {
    position: relative;
    flex: 0 0 var(--width-slide);
    width: var(--width-slide);
    height: 100%;
    overflow: hidden;
    opacity: 1 !important;

    img {
        position: absolute;
        width: 120%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }
}
