#BlockLanding {
    --padding-h: var(--padding-m);
    --padding-v: var(--padding-l);
    
    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xs);
        --padding-v: var(--padding-s);
    }
}

#BlockLanding {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    position: relative;

    @include font-small();
    
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
        @include z-index(0)
    }
    
    > div {
        background-color: var(--white);
        border-radius: rem(25);
        @include z-index(1);
        position: relative;
        padding: var(--padding-v) var(--padding-h);
    }

    h1 {
        @include font-script-xxxlarge();
        color: var(--lightGreen);
        margin-bottom: var(--padding-s);
    }
    
    .date {
        @include font-large();
        margin-bottom: var(--padding-s);
    }
    
    .countdown {
        @include font-script-xlarge();
        color: var(--lightGreen);
        margin-bottom: var(--padding-m);
    }
    
    .text{
        p:not(:last-child){
            margin-bottom: var(--padding-xs);
        }
    }
    
    .button {
        margin-top: var(--padding-s);
        display: inline-block;
        text-decoration: underline;
    }

    .scroll {
        @include basic-a();
        @include font-script-xlarge();
        color: var(--lightGreen);
        padding: var(--padding-xxs);
        background-color: var(--white);
        border-radius: rem(10);
     
        @include isCursor() {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    @media (max-width: $smartphone) {
        padding: calc(var(--header-height) + var(--padding-v)) var(--padding-h) var(--padding-v);

        .countdown {
            margin-bottom: var(--padding-s);
        }
        
        > div {
            border-radius: rem(15);
        }
        
        .scroll {
            display: none;
        }
    }

    @media (min-width: $smartphone) {
        height: 100vh;

        .button {
            margin-top: var(--padding-s);
        }

        .scroll {
            position: absolute;
            right: var(--padding-h);
            bottom: var(--padding-h);
        }
    }
}
