#BlockBank {
    --padding-h: var(--padding-m);
    --padding-v: var(--padding-xl);
    
    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xs);
        --padding-v: var(--padding-m);
    }
}

#BlockBank {
    padding: var(--padding-v) var(--padding-h);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;

    @include font-medium();

    h2 {
        @include font-script-xxlarge();
        color: var(--lightGreen);
        margin-bottom: var(--padding-s);
    }

    h3 {
        @include font-xlarge();
        color: var(--lightGreen);
        margin-top: var(--padding-s);
    }

    .text{   
        p:not(:last-child){
            margin-bottom: var(--padding-xs);
        }
    }

    @media (min-width: $smartphone) {
        min-height: 70vh;

        h2 {
            margin-bottom: var(--padding-l);
        }

        h3 {
            margin-top: var(--padding-l);
        }
        
        .text{
            max-width: rem(1040);

            p:not(:last-child){
                margin-bottom: var(--padding-s);
            }
        }
    }
}
