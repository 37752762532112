:root {
    --white: #f4f4f4;
    --black: #000000;
    --grey: #D9D9D9;
    --green: #5A5843;
    --lightGreen: #5A7B5D;
    --lightGreen20: rgb(90, 123, 93, .2);
    --lightGreen40: rgb(90, 123, 93, .4);
    --lightGreen60: rgb(90, 123, 93, .6);
}
