@use "sass:math";

:root {
    --font-sans: 'PPWriter-ThinText', sans-serif;
    --font-sans-thin: 'PPWriter-ThinText', sans-serif;
    --font-script: 'PinyonScript-Regular', sans-serif;

    --font-size-xxxlarge: #{math.div(162px, 16px) * 1rem};
    --font-size-xxlarge: #{math.div(90px, 16px) * 1rem};
    --font-size-xlarge: #{math.div(54px, 16px) * 1rem};
    --font-size-large-2: #{math.div(36px, 16px) * 1rem};
    --font-size-large: #{math.div(28px, 16px) * 1rem};
    --font-size-medium: #{math.div(22px, 16px) * 1rem};
    --font-size-small: #{math.div(20px, 16px) * 1rem};
    --font-size-base: #{math.div(16px, 16px) * 1rem};

    --line-height-sans: 1.28;
    --line-height-script: 1.2;
    --letter-spacing: -0.02em;

    --min-font-size: 11px;
    --max-font-size: 15px;
    --font-size: clamp(var(--min-font-size), 1.1111vw * var(--zoom), var(--max-font-size));

    font-size: var(--font-size);
    
    @media (max-width: $smartphone) {
        --font-size: 16px;

        --font-size-xxxlarge: #{math.div(64px, 16px) * 1rem};
        --font-size-xxlarge: #{math.div(42px, 16px) * 1rem};
        --font-size-xlarge: #{math.div(26px, 16px) * 1rem};
        --font-size-large-2: #{math.div(22px, 16px) * 1rem};
        --font-size-large: #{math.div(20px, 16px) * 1rem};
        --font-size-medium: #{math.div(18px, 16px) * 1rem};
        --font-size-small: #{math.div(16px, 16px) * 1rem};
        --font-size-base: #{math.div(14px, 16px) * 1rem};
    }
}

@mixin font-sans($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: normal;
    letter-spacing: var(--letter-spacing);
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}

@mixin font-script($line-height:0) {
    font-family: var(--font-script);
    font-weight: normal;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-script);  }
}

@mixin font-script-large($line-height: 0) {
    @include font-script();
    font-size: var(--font-size-large);
}

@mixin font-script-large-2($line-height: 0) {
    @include font-script();
    font-size: var(--font-size-large-2);
}

@mixin font-script-xlarge($line-height: 0) {
    @include font-script();
    font-size: var(--font-size-xlarge);
}

@mixin font-script-xxlarge($line-height: 0) {
    @include font-script();
    font-size: var(--font-size-xxlarge);
}

@mixin font-script-xxxlarge($line-height: 0) {
    @include font-script();
    font-size: var(--font-size-xxxlarge);
}

@mixin font-xlarge($line-height: 0) {
    @include font-sans();
    font-size: var(--font-size-xlarge);
}

@mixin font-large($line-height: 0) {
    @include font-sans();
    font-size: var(--font-size-large);
}

@mixin font-medium($line-height: 0) {
    @include font-sans();
    font-size: var(--font-size-medium);
}

@mixin font-base($line-height: 0) {
    @include font-sans();
    font-size: var(--font-size-base);
}

@mixin font-small($line-height: 0) {
    @include font-sans();
    font-size: var(--font-size-small);
}
