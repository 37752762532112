#BlockMarquee {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--header-height);
    
    @include font-script-xlarge();
    @include z-index($z-index-header);
    
    &::before {
        @include z-index(0);
        content: '';
        position: absolute;
        background-color: var(--white);
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    
    &::after {
        @include z-index(1);
        content: '';
        position: absolute;
        background-color: var(--lightGreen20);
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .basic-marquee {
        padding: var(--padding-xxs) 0;
        color: var(--green);
        @include z-index(1);
        position: relative;

        span {
            padding-left: var(--padding-xs);
            margin-right: var(--padding-xxs);
        }

        .aux {
            height: 100%;
            display: inline-flex;
            align-items: center;
            white-space: nowrap;
            padding-left: 0;
        }
    }
}
