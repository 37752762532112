#Footer {
    --color: var(--lightGreen);
}

#Footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10vh;

    @include font-base();

    a {
        @include basic-a();
        color: var(--color);
        text-decoration: underline;
    }

    @media (max-width: $smartphone) {
        text-align: center;
    }
}
