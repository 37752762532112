@mixin button {
    @include basic-a();
    @include font-script-large-2();
    
    align-items: center;
    appearance: none;
    cursor: pointer;
    color: var(--lightGreen);
    display: flex;
    justify-content: flex-start;
    padding: 0;
    position: relative;
    text-align: center;
    vertical-align: top;

    * { pointer-events: none; }
    span { display: block; }

    @include isCursor() {
        &:hover {
            text-decoration: underline;
            // background-color: var(--lightGreen);
            // color: var(--black);
        }
    }
}

.button {
    @include button;
}
