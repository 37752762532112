#BlockDance {
    --padding-h: var(--padding-m);
    --padding-v: var(--padding-l);
    
    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xs);
        --padding-v: var(--padding-m);
    }
}

#BlockDance {
    padding: var(--padding-v) var(--padding-h) var(--padding-s);
    text-align: center;

    @include font-large();

    h2 {
        @include font-script-xxlarge();
        color: var(--lightGreen);
        margin-bottom: var(--padding-s);
    }

    .text{   
        p:not(:last-child){
            margin-bottom: var(--padding-xs);
        }
    }

    @media (max-width: $smartphone) {
        .image-wrapper {
            margin-bottom: var(--padding-m);
            img {
                width: 100%;
                height: auto;
            }
        }
    }

    @media (min-width: $smartphone) {
        display: flex;
        align-items: flex-end;
        justify-content: space-around;

        h2 {
            @include font-script-xxlarge();
            color: var(--lightGreen);
            margin-bottom: var(--padding-m);
        }
        
        .image-wrapper {
            flex: 0 0 25vw;
            margin: 0 7vw;

            img {
                width: 100%;
            }
        }

        .text-wrapper {
            flex-grow: 1;

            img {
                width: 100%;
            }
        }

        .text {
            padding: 0 5%;

            p:not(:last-child){
                margin-bottom: var(--padding-s);
            }
        }
    }
}
